import React, { useState, useContext } from 'react';
import './language-switcher.css';
import { LanguageContext } from './language-context';

function LanguageSwitcher() {
  const { language, changeLanguage } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="language-switcher">
      <button className="language-switcher-btn" onClick={() => setIsOpen(!isOpen)}>
        {language.toUpperCase()}
      </button>
      {isOpen && (
        <div className="language-switcher-menu">
          <button 
            className={language === 'ua' ? 'active' : ''}
            onClick={() => changeLanguage('ua')}
          >
            {language === 'ua' && <span className="checkmark">✔</span>} UA
          </button>
          <button 
            className={language === 'en' ? 'active' : ''}
            onClick={() => changeLanguage('en')}
          >
            {language === 'en' && <span className="checkmark">✔</span>} EN
          </button>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
