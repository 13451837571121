import React from 'react';
//import TradingChart from '../TraidingView/traiding-chart';
import './main-content.css';

function MainContent() {


  return (
    <div className="main-content">      
      <h1>Головний компонент</h1>
    </div>
  );
}

export default MainContent;
