import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Імпортуй useNavigate для навігації
import './header-intro.css';
import '../../../styles.css';
import LanguageSwitcher from '../../language-switcher/language-switcher';
import ThemeSwitcher from '../../theme-switcher/theme-switcher';
import { ThemeContext } from '../../theme-switcher/theme-context';
import { FaBars, FaTimes } from 'react-icons/fa'; // Іконки бургер-меню

function HeaderIntro() {
  const { t } = useTranslation();
  const { isDarkMode } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); // Використовуємо useNavigate для навігації

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleResize = () => {
    if (window.innerWidth > 1100) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="header">
      <div className="logo">
        <img
          src={isDarkMode ? "/logo-dark.png" : "/logo.png"}
          alt="Site Logo"
        />
      </div>
      <button className="burger-menu" onClick={toggleMenu} aria-label="Toggle menu">
        {menuOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
      </button>
      <nav className={`navbar ${menuOpen ? 'active' : ''}`}>
        <ul>
          <li><a href="#home" onClick={() => setMenuOpen(false)}>{t('header.home')}</a></li>
          <li><a href="#about" onClick={() => setMenuOpen(false)}>{t('header.about')}</a></li>
          <li><a href="#services" onClick={() => setMenuOpen(false)}>{t('header.services')}</a></li>
          <li><a href="#contact" onClick={() => setMenuOpen(false)}>{t('header.contact')}</a></li>
        </ul>
        {menuOpen && (
          <div>
            <div className="setting-mobile-buttons">
              <ThemeSwitcher />
            </div>
            <div className="burger-buttons">
              <button className="burger-btn" onClick={() => navigate('/login')}>{t('header.login')}</button>
              <LanguageSwitcher />
              <button className="burger-btn" onClick={() => navigate('/register')}>{t('header.signup')}</button>
            </div>
          </div>
        )}
      </nav>
      <nav className={`header-buttons ${menuOpen ? 'active' : ''}`}>
        <button className="login-btn" onClick={() => navigate('/login')}>{t('header.login')}</button>
        <button className="signup-btn" onClick={() => navigate('/register')}>{t('header.signup')}</button>
        <LanguageSwitcher />
        <ThemeSwitcher />
      </nav>
    </header>
  );
}

export default HeaderIntro;
