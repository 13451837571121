import React, { useState } from 'react';
import './create-bot.css';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function CreateBot() {
  const [botName, setBotName] = useState('');
  const [botBudget, setBotBudget] = useState('');
  const [botLimit, setBotLimit] = useState('');
  const [botInterval, setBotInterval] = useState('15m'); // За замовчуванням
  const [coins, setCoins] = useState(''); // Додано стан для монет
  const [rsi, setRsi] = useState(''); // Додано стан для RSI
  const [profit, setProfit] = useState(''); // Додано стан для profit
  const [stoploss, setStoploss] = useState(''); // Додано стан для stoploss
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const token = localStorage.getItem('token');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
  
    // Перевірка на заповненість усіх полів
    if (!botName || !botBudget || !botLimit || !coins || !rsi || !profit || !stoploss) {
      setErrorMessage('Усі поля обов\'язкові для заповнення.');
      return;
    }
  
    try {
      const orderValue = botBudget / botLimit; // Обчислення order_value
  
      // Парсимо JSON з монет
      const coinsJson = {};
      const coinArray = coins.split(',').map(coin => coin.trim().toUpperCase());
      coinArray.forEach(coin => {
        coinsJson[coin] = { rsi: parseInt(rsi), profit: parseFloat(profit), stoploss: parseFloat(stoploss) }; // Задаємо значення для монет
      });
  
      const response = await axios.post(
        `${apiUrl}/bots`, // Використовуємо apiUrl
        {
          name: botName,
          type: 'RSI',
          budget: parseFloat(botBudget), // Переконайтеся, що це число
          coins: coinsJson, // Додаємо монети
          settings: {
            bot_limit: parseFloat(botLimit), // Переконайтеся, що це число
            order_value: orderValue,
            interval: botInterval // Додаємо interval в налаштування
          },
          status: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Bot created successfully:', response.data);
      setSuccessMessage('Бота успішно створено!');
      // Очищення полів після успішного створення
      setBotName('');
      setBotBudget('');
      setBotLimit('');
      setCoins(''); 
      setRsi(''); 
      setProfit(''); 
      setStoploss(''); 
      setBotInterval('15m'); 
    } catch (error) {
      console.error('Error creating bot:', error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message || 'Сталася помилка');
      } else {
        setErrorMessage('Сталася помилка при створенні бота');
      }
    }
  };
  

  return (
    <div className="create-bot">
      <h1>Створення нового бота</h1>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form className="create-bot-form" onSubmit={handleSubmit}>
        <label htmlFor="botName">Назва бота:</label>
        <input
          type="text"
          id="botName"
          name="botName"
          placeholder="Введіть назву бота"
          value={botName}
          onChange={(e) => setBotName(e.target.value)}
        />

        <label htmlFor="botBudget">Бюджет:</label>
        <input
          type="number"
          id="botBudget"
          name="botBudget"
          placeholder="Введіть бюджет"
          value={botBudget}
          onChange={(e) => setBotBudget(e.target.value)}
        />

        <label htmlFor="botLimit">Ліміт ордерів:</label>
        <input
          type="number"
          id="botLimit"
          name="botLimit"
          placeholder="Введіть ліміт ордерів"
          value={botLimit}
          onChange={(e) => setBotLimit(e.target.value)}
        />

        <label htmlFor="coins">Монети (через кому):</label>
        <input
          type="text"
          id="coins"
          name="coins"
          placeholder='Введіть монети, наприклад: BTCUSDT, ETHUSDT, DOTUSDT'
          value={coins}
          onChange={(e) => setCoins(e.target.value)}
        />

        <label htmlFor="rsi">RSI:</label>
        <input
          type="number"
          id="rsi"
          name="rsi"
          placeholder="Введіть значення RSI"
          value={rsi}
          onChange={(e) => setRsi(e.target.value)}
        />

        <label htmlFor="profit">Profit:</label>
        <input
          type="number"
          id="profit"
          name="profit"
          placeholder="Введіть значення Profit"
          value={profit}
          onChange={(e) => setProfit(e.target.value)}
        />

        <label htmlFor="stoploss">Stoploss:</label>
        <input
          type="number"
          id="stoploss"
          name="stoploss"
          placeholder="Введіть значення Stoploss"
          value={stoploss}
          onChange={(e) => setStoploss(e.target.value)}
        />

        <label htmlFor="botInterval">Інтервал:</label>
        <select 
          id="botInterval" 
          name="botInterval" 
          value={botInterval} 
          onChange={(e) => setBotInterval(e.target.value)}
        >
          <option value="15m">15m</option>
          <option value="1h">1h</option>
          <option value="4h">4h</option>
          <option value="1d">1d</option>
        </select>

        <button type="submit" className="create-bot-button">Створити бота</button>
      </form>
    </div>
  );
}

export default CreateBot;
