import React, { useState, useEffect, useContext } from 'react';
import './profile.css';
import { ThemeContext } from '../../components/theme-switcher/theme-context';
import { FaChevronDown } from 'react-icons/fa';
import ThemeSwitcher from '../../components/theme-switcher/theme-switcher';
import LanguageSwitcher from '../../components/language-switcher/language-switcher';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

function Profile() {
    const { isDarkMode } = useContext(ThemeContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${apiUrl}/users/logout`, { // Використовуємо apiUrl
                method: 'POST',
            });
            if (response.ok) {
                localStorage.removeItem('token'); // Clear token from localStorage
                navigate('/'); // Redirect to homepage
            } else {
                console.error('Failed to log out');
            }
        } catch (err) {
            console.error('Error during logout:', err);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                setError('Token is missing');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/users/user`, { // Використовуємо apiUrl
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    console.log(response.status);
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setUserData(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchUserData();
    }, []);

    return (
        <div className="profile-container">
            <img
                src={isDarkMode ? "/slogan-background-dark.png" : "/slogan-background.png"}
                alt="Slogan Background"
                className="main-content-image"
            />
            <div className="profile-blocks">
                <div className="profile-block-statistics">
                    <h2>Профіль - Блок 3</h2>
                    {error && <p className="error-message">{error}</p>}
                    {userData && (
                        <div>
                            <p>Нікнейм: {userData.nickname}</p>
                            <p>Ім'я: {userData.name}</p>
                        </div>
                    )}
                </div>
                <div className="profile-block-prising">
                    <div className="subscription-header">
                        <h2>План підписки (MAX)</h2>
                        <button className="dropdown-btn" onClick={toggleDropdown} aria-label="Show more details">
                            <FaChevronDown size={20} />
                        </button>
                    </div>
                    <hr className="divider" />
                    <div className="subscription-details">
                        <div className="detail">
                            <p className="number">17.09.24</p>
                            <p className="label">Підписка діє до</p>
                        </div>
                        <div className="detail">
                            <p className="number">5000$</p>
                            <p className="label">Чистий прибуток</p>
                        </div>
                    </div>
                    <button className="change-plan-btn">Змінити план</button>
                    {dropdownOpen && (
                        <div className="dropdown-content">
                            <p><strong>План підписки MAX</strong></p>
                            <hr />
                            <p><strong>5000$</strong> <span>об'єм коштів</span></p>
                            <p><strong>250</strong> <span>монет на вибір</span></p>
                            <p><strong>50</strong> <span>ботів</span></p>
                            <p><strong>3</strong> <span>біржі</span></p>
                            <p><strong>150$</strong> <span>на місяць</span></p>
                        </div>
                    )}
                </div>
                <div className="profile-block-settings">
                    <div className='profile-block-settings-buttons'>
                        <ThemeSwitcher />
                        <LanguageSwitcher />
                        <button onClick={handleLogout}>Вийти з акаунту</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
