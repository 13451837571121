import './intro.css';
import React from 'react';

import Header from '../../components/headers/header-intro/header-intro'
import Slogan from '../../components/slogan/slogan'

function Intro() {
  return (
    <div>
      <Header></Header>
      <Slogan></Slogan>
    </div>
  );
}

export default Intro;
