import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './bot-detail.css';

function BotDetail() {
  const { botId } = useParams();
  const [bot, setBot] = useState(null);
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState('Purchases');
  const token = localStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bots/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBot(response.data);
      } catch (error) {
        console.error('Error fetching bot details:', error);
      }
    };

    fetchBotDetails();
  }, [botId, token, apiUrl]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/orders/bot/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const ordersData = response.data;
        
        const purchases = ordersData.filter(order => order.side === 'BUY' && order.status === 'NEW');
        const sales = ordersData
          .filter(order => order.side === 'SELL' && order.status === 'NEW' && order.type === 'LIMIT_MAKER')
          .map(order => {
            const stopOrder = ordersData.find(o => o.symbol === order.symbol && o.type === 'STOP_LOSS');
            const limitOrder = ordersData.find(o => o.symbol === order.symbol && o.type === 'LIMIT');
            
            return {
              ...order,
              stopPrice: stopOrder ? stopOrder.price : null,
              limitAmount: limitOrder ? limitOrder.amount : null,
            };
          });
        
        const history = renderOrderHistory(ordersData);
          
        setOrders({ purchases, sales, history });
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
  
    fetchOrders();
  }, [botId, token, apiUrl]); 

  const renderOrderHistory = (ordersData) => {
    return ordersData
      .filter(order => order.status !== 'NEW' && order.side === 'BUY')
      .map(order => {
        const orderProfit = ordersData.find(o => o.symbol === order.symbol && o.side === 'SELL' && o.type === 'LIMIT_MAKER');
        const orderLoss = ordersData.find(o => o.symbol === order.symbol && o.side === 'SELL' && o.type === 'STOP_LOSS');

        if ((!orderProfit || orderProfit.status !== 'FILLED') && (!orderLoss || orderLoss.status !== 'FILLED')) {
          return {
            id: order.id,
            symbol: order.symbol,
            status: 'not sold',
            purchased: order.price,
            sold: '—',
            amount: order.amount,
            profit: 0,
            rsi: order.rsi,
          };
        }        

        if (orderProfit && orderProfit.status === 'FILLED') {
          return {
            id: order.id,
            symbol: order.symbol,
            status: 'profit',
            purchased: order.price,
            sold: orderProfit.price,
            amount: order.amount,
            profit: orderProfit.amount-order.amount,
            rsi: order.rsi,
          };
        }

        if (orderLoss && orderLoss.status === 'FILLED') {
          return {
            id: order.id,
            symbol: order.symbol,
            status: 'loss',
            purchased: order.price,
            sold: orderLoss.price,
            amount: order.amount,
            profit: orderLoss.amount-order.amount,
            rsi: order.rsi,
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const handleStatusToggle = async () => {
    const newStatus = bot.status === 1 ? 0 : 1;

    try {
      await axios.put(
        `${apiUrl}/bots/${botId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBot((prevState) => ({ ...prevState, status: newStatus }));
    } catch (error) {
      console.error('Error updating bot status:', error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this order?');
    if (isConfirmed) {
      try {
        await axios.delete(`${apiUrl}/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
      } catch (error) {
        alert('Error deleting order: ' + error.message);
        console.error('Error deleting order:', error);
      }
    }
  };  

  if (!bot) {
    return <div>Завантаження...</div>;
  }

  // Динамічно генеруємо дані замовлень для різних вкладок
  const renderOrders = () => {
    switch (activeTab) {
      case 'Purchases':
        return (
          <ul className="orders-list">
            {orders.purchases && orders.purchases.map((order) => (
              <li key={order.id} className="order-item">
                <div className="order-status">
                  <div className="status-circle status-not-executed"></div>
                </div>
                <div className="order-symbol">
                  <span className="symbol-large">{order.symbol}</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.price} USDT</span>
                  <span className="price-label">Exchange rate</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.amount} USDT</span>
                  <span className="price-label">Amount</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.rsi}</span>
                  <span className="price-label">RSI</span>
                </div>
                <div className="order-remove" onClick={() => handleDeleteOrder(order.id)}>
                  <span className="remove-icon">✖</span>
                </div>
              </li>
            ))}
          </ul>
        );
  
      case 'Sales':
        return (
          <ul className="orders-list">
            {orders.sales && orders.sales.map((order) => (
              <li key={order.id} className="order-item">
                <div className="order-status">
                  <div className="status-circle status-not-executed"></div>
                </div>
                <div className="order-symbol">
                  <span className="symbol-large">{order.symbol}</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.price} USDT</span>
                  <span className="price-label">Goal</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.stopPrice} USDT</span>
                  <span className="price-label">Stop</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.limitAmount} USDT</span>
                  <span className="price-label">Amount</span>
                </div>
                <div className="order-remove" onClick={() => handleDeleteOrder(order.id)}>
                  <span className="remove-icon">✖</span>
                </div>
              </li>
            ))}
          </ul>
        );
  
      case 'History':
        return (
          <ul className="orders-list">
            {orders.history && orders.history.map((order) => (
              <li key={order.id} className="order-item">
                <div className="order-status">
                  <div className={`status-circle ${order.status === 'profit' ? 'status-profit' : order.status === 'loss' ? 'status-loss' : 'status-not-sold'}`}></div>
                </div>
                <div className="order-symbol">
                  <span className="symbol-large">{order.symbol}</span>
                  <span className="price-label">{order.status === 'profit' ? 'Profit' : order.status === 'loss' ? 'Loss' : 'Not sold'}</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.purchased} USDT</span>
                  <span className="price-label">Purchased</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.sold} USDT</span>
                  <span className="price-label">Sold</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.amount} USDT</span>
                  <span className="price-label">Amount</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.profit} USDT</span>
                  <span className="price-label">Profit</span>
                </div>
                <div className="order-value">
                  <span className="value-large">{order.rsi}</span>
                  <span className="price-label">RSI</span>
                </div>
              </li>
            ))}
          </ul>
        );
      default:
        return null;
    }
  };  

  return (
    <div className="bot-detail-container">
      <div className="bot-detail-row">
        <div className="bot-detail-item">
          <span className="bot-detail-label">Name</span>
          <p>{bot.name}</p>
        </div>
        <div className="bot-detail-item">
          <span className="bot-detail-label">Type</span>
          <p>{bot.type}</p>
        </div>
        <div className="bot-detail-item">
          <span className="bot-detail-label">Budget</span>
          <p>{bot.budget}</p>
        </div>
        <div className="bot-detail-item">
          <span className="bot-detail-label">Status</span>
          <div className="status-toggle" onClick={handleStatusToggle}>
            <span>{bot.status === 0 ? 'Off' : 'On'}</span>
            <label className="switch">
              <input type="checkbox" checked={bot.status === 1} readOnly />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      {/* Orders Section with Tabs */}
      <div className="orders-section">     
        {/* Tabs */}
        <div className="tabs">
          {['Purchases', 'Sales', 'History'].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Render Orders List */}
        {renderOrders()}
      </div>
    </div>
  );
}

export default BotDetail;