import './slogan.css'; 
import React, { useContext } from 'react';
import { ThemeContext } from '../theme-switcher/theme-context';
import { useTranslation } from 'react-i18next';

function Slogan() {
  const { isDarkMode } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div className={`slogan-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <img
        src={isDarkMode ? "/slogan-background-dark.png" : "/slogan-background.png"}
        alt="Slogan Background"
        className="slogan-image"
      />
      <h1 className="slogan-text">{t('slogan.message')}</h1>
      <p className="slogan-subtext">{t('slogan.subtext')}</p>
      <button className="start-btn">{t('slogan.start_button')}</button>
    </div>
  );
}

export default Slogan;
