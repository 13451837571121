import React, { useContext } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import { ThemeContext } from './theme-context';
import './theme-switcher.css'

function ThemeSwitcher() {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
      <button onClick={toggleTheme} aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}>
        {isDarkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
      </button>
  );
}

export default ThemeSwitcher;
