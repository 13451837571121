import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './i18n';

import Intro from './pages/intro/intro'
import Registration from './pages/register/registration'
import Login from './pages/login/login'
import Dashboard from './pages/dashboard/dashboard';
import Profile from './pages/profile/profile';

function App() {
  return (
    <BrowserRouter>
        <main className="main">
          <div className="main-inner">
          <Routes>
              <Route path="/" element={<Intro/>}/>
              <Route path="/register" element={<Registration />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </div>
        </main>
    </BrowserRouter>
  );
}

export default App;
