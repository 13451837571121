import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './header-main.css';
import '../../../styles.css';
import { Link } from 'react-router-dom'; // Додано для маршрутизації
import { ThemeContext } from '../../theme-switcher/theme-context';
import { FaBars, FaTimes, FaUser } from 'react-icons/fa'; // Додано іконку профіля

function HeaderMain({ onMenuToggle }) {
  const { t } = useTranslation();
  const { isDarkMode } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    onMenuToggle(!menuOpen);
  };

  const handleResize = () => {
    if (window.innerWidth > 800) {
      setMenuOpen(false);
      onMenuToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <header className="header-main">
      <div className="logo">
        <img
          src={isDarkMode ? "/logo-dark.png" : "/logo.png"}
          alt="Site Logo"
        />
      </div>
      <nav className={`navbar-main ${menuOpen ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to="/dashboard" onClick={() => setMenuOpen(false)}>{t('header.main')}</Link>
          </li>
        </ul>
      </nav>
      <div className="header-main-buttons">
        <Link to="/profile" className="profile-button" aria-label="Profile">
          <FaUser size={25} />
        </Link>
        <button className="burger-menu" onClick={toggleMenu} aria-label="Toggle menu">
          {menuOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
        </button>
      </div>
    </header>
  );
}

export default HeaderMain;