import React, { useState } from 'react';
import HeaderMain from '../../components/headers/header-main/header-main';
import Sidebar from '../../components/sidebar/sidebar';
import MainContent from '../../components/main-content/main-content';
import CreateBot from '../../components/create-bot/create-bot';
import BotDetail from '../../components/bot-detail/bot-detail';
import BotEdit from '../../components/bot-edit/bot-edit';
import './dashboard.css';
import { Routes, Route } from 'react-router-dom';

function Dashboard() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleMenuToggle = (isOpen) => {
    setSidebarOpen(isOpen);
  };

  return (
    <div className="dashboard">
      <HeaderMain onMenuToggle={handleMenuToggle} className="dasboardheader" />
      <div className="dashboard-container">
        <Sidebar isOpen={isSidebarOpen} />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/bot/create" element={<CreateBot />} />
            <Route path="/bot/:botId" element={<BotDetail />} />
            <Route path="/bot/edit/:botId" element={<BotEdit />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
