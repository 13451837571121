import React, { useEffect, useState } from 'react';
import './sidebar.css';
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

const apiUrl = process.env.REACT_APP_API_URL;

function Sidebar({ isOpen }) {
  const { t } = useTranslation();
  const [bots, setBots] = useState([]);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bots/`, { // Використовуємо apiUrl
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBots(response.data);
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };

    fetchBots();
  }, [token]);

  const handleAddBotClick = () => {
    navigate('bot/create');
  };

  const handleBotClick = (botId) => {
    navigate(`bot/${botId}`);
  };

  const handleEditBotClick = (botId, event) => {
    event.stopPropagation();
    navigate(`bot/edit/${botId}`);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <ul>
        {bots.length > 0 ? (
          bots.map((bot) => (
            <li 
            key={bot.id} 
            className="bot-list-item" 
            onClick={() => handleBotClick(bot.id)} 
            style={{ cursor: 'pointer' }}>
              <span>{bot.name}</span>
              <FaEdit
                className="edit-icon"
                onClick={(event) => handleEditBotClick(bot.id, event)}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            </li>
          ))
        ) : (
          <li>{t('sidebar.no_bots')}</li>
        )}
        <li onClick={handleAddBotClick}>{t('sidebar.add_bot')}<span className="plus-icon">+</span> </li>
      </ul>
    </div>
  );
}

export default Sidebar;
