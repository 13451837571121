import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './login.css';

const apiUrl = process.env.REACT_APP_API_URL;

function Login() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/users/login`, { // Використовуємо apiUrl
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      setMessage(result.message);

      if (response.ok) {
        localStorage.setItem('token', result.token);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred.');
    }
  };

  return (
    <div className="login-container">
      <h1>{t('header.login')}</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <label>
          {t('login.email')}
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder={t('login.email')}
          />
        </label>
        <label>
          {t('login.password')}
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            placeholder={t('login.password')}
          />
        </label>
        <button type="submit">{t('login.submit_button')}</button>
      </form>
      {message && <p className="response-message">{message}</p>}
    </div>
  );
}

export default Login;
