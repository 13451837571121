import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './bot-edit.css';

const apiUrl = process.env.REACT_APP_API_URL;

function BotEdit() {
  const { botId } = useParams();
  const [bot, setBot] = useState({
    name: '',
    type: '',
    budget: '',
    coins: {},
    settings: {
      bot_limit: '',
      order_value: 0,
      interval: '15m',
    },
    status: 0,
  });
  const [coinsArray, setCoinsArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bots/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const botData = response.data;

        // Парсимо монети в масив для редагування
        const coinsData = botData.coins || {};
        const coinsArray = Object.keys(coinsData).map((coinSymbol) => ({
          symbol: coinSymbol,
          rsi: coinsData[coinSymbol].rsi,
          profit: coinsData[coinSymbol].profit,
          stoploss: coinsData[coinSymbol].stoploss,
        }));

        setCoinsArray(coinsArray);
        setBot(botData);
      } catch (error) {
        console.error('Error fetching bot details:', error);
      }
    };

    fetchBotDetails();
  }, [botId, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBot((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBudgetChange = (e) => {
    const budget = e.target.value;
    setBot((prevState) => {
      const botLimit = prevState.settings.bot_limit || 1;
      const orderValue = budget / botLimit;
      return {
        ...prevState,
        budget: budget,
        settings: {
          ...prevState.settings,
          order_value: orderValue,
        },
      };
    });
  };

  const handleBotLimitChange = (e) => {
    const botLimit = e.target.value;
    setBot((prevState) => {
      const budget = prevState.budget || 0;
      const orderValue = budget / botLimit;
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          bot_limit: botLimit,
          order_value: orderValue,
        },
      };
    });
  };

  const handleIntervalChange = (e) => {
    const interval = e.target.value;
    setBot((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        interval,
      },
    }));
  };

  const handleCoinChange = (index, field, value) => {
    setCoinsArray((prevCoins) => {
      const newCoins = [...prevCoins];
      newCoins[index] = {
        ...newCoins[index],
        [field]: value,
      };
      return newCoins;
    });
  };

  const handleAddCoin = () => {
    setCoinsArray((prevCoins) => {
      let newCoin = { symbol: '', rsi: '', profit: '', stoploss: '' };
  
      if (prevCoins.length > 0) {
        const lastCoin = prevCoins[prevCoins.length - 1];
        // Копіюємо значення з останньої монети
        newCoin = {
          symbol: '',
          rsi: lastCoin.rsi,
          profit: lastCoin.profit,
          stoploss: lastCoin.stoploss,
        };
      }
  
      return [...prevCoins, newCoin];
    });
  };

  const handleRemoveCoin = (index) => {
    setCoinsArray((prevCoins) => prevCoins.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    setErrorMessage('');
    // Перевірка на заповненість полів
    if (!bot.name || !bot.budget || !bot.settings.bot_limit || coinsArray.length === 0) {
      setErrorMessage('Усі поля обов\'язкові для заповнення.');
      return;
    }

    // Перевірка, чи всі монети заповнені правильно
    for (const coin of coinsArray) {
      if (!coin.symbol || !coin.rsi || !coin.profit || !coin.stoploss) {
        setErrorMessage('Усі поля для кожної монети повинні бути заповнені.');
        return;
      }
    }

    try {
      // Будуємо об'єкт монет з масиву
      const coins = {};
      coinsArray.forEach((coin) => {
        coins[coin.symbol.toUpperCase()] = {
          rsi: parseInt(coin.rsi),
          profit: parseFloat(coin.profit),
          stoploss: parseFloat(coin.stoploss),
        };
      });

      // Обчислюємо order_value
      const orderValue = bot.budget / bot.settings.bot_limit;

      const updatedBot = {
        ...bot,
        coins,
        settings: {
          ...bot.settings,
          order_value: orderValue,
        },
      };

      await axios.put(
        `${apiUrl}/bots/${botId}`,
        updatedBot,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(`/dashboard/bot/${botId}`);
    } catch (error) {
      console.error('Error updating bot details:', error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message || 'Сталася помилка');
      } else {
        setErrorMessage('Сталася помилка при оновленні бота');
      }
    }
  };

  return (
    <div className="bot-edit-container">
      <h1>Редагування бота</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="bot-edit-row">
        <div className="bot-edit-item">
          <label className="bot-edit-label">Назва бота:</label>
          <input
            type="text"
            name="name"
            value={bot.name}
            onChange={handleInputChange}
            className="bot-edit-input"
          />
        </div>
        <div className="bot-edit-item">
          <label className="bot-edit-label">Тип:</label>
          <input
            type="text"
            name="type"
            value={bot.type}
            onChange={handleInputChange}
            className="bot-edit-input"
            readOnly
          />
        </div>
        <div className="bot-edit-item">
          <label className="bot-edit-label">Бюджет:</label>
          <input
            type="number"
            name="budget"
            value={bot.budget}
            onChange={handleBudgetChange}
            className="bot-edit-input"
          />
        </div>
        <div className="bot-edit-item">
          <label className="bot-edit-label">Ліміт ордерів:</label>
          <input
            type="number"
            name="bot_limit"
            value={bot.settings.bot_limit}
            onChange={handleBotLimitChange}
            className="bot-edit-input"
          />
        </div>
        <div className="bot-edit-item">
          <label className="bot-edit-label">Order Value:</label>
          <input
            type="number"
            value={bot.settings.order_value}
            readOnly
            className="bot-edit-input"
          />
        </div>
        <div className="bot-edit-item">
          <label className="bot-edit-label">Інтервал:</label>
          <select
            name="interval"
            value={bot.settings.interval}
            onChange={handleIntervalChange}
            className="bot-edit-input"
          >
            <option value="15m">15m</option>
            <option value="1h">1h</option>
            <option value="4h">4h</option>
            <option value="1d">1d</option>
          </select>
        </div>
        <div className="bot-edit-item">
          <label className="bot-edit-label">Статус:</label>
          <select
            name="status"
            value={bot.status}
            onChange={handleInputChange}
            className="bot-edit-input"
          >
            <option value={1}>Активний</option>
            <option value={0}>Зупинений</option>
          </select>
        </div>
      </div>

      <div className="bot-edit-coins">
        <h2>Монети</h2>
        {coinsArray.map((coin, index) => (
          <div key={index} className="bot-edit-coin">
            <input
              type="text"
              name="symbol"
              value={coin.symbol}
              onChange={(e) => handleCoinChange(index, 'symbol', e.target.value)}
              placeholder="Символ монети"
              className="bot-edit-input"
            />
            <input
              type="number"
              name="rsi"
              value={coin.rsi}
              onChange={(e) => handleCoinChange(index, 'rsi', e.target.value)}
              placeholder="RSI"
              className="bot-edit-input"
            />
            <input
              type="number"
              name="profit"
              value={coin.profit}
              onChange={(e) => handleCoinChange(index, 'profit', e.target.value)}
              placeholder="Profit"
              className="bot-edit-input"
            />
            <input
              type="number"
              name="stoploss"
              value={coin.stoploss}
              onChange={(e) => handleCoinChange(index, 'stoploss', e.target.value)}
              placeholder="Stoploss"
              className="bot-edit-input"
            />
            <button onClick={() => handleRemoveCoin(index)} className="bot-edit-remove-button">
              Видалити
            </button>
          </div>
        ))}
        <button onClick={handleAddCoin} className="bot-edit-add-button">
          Додати монету
        </button>
      </div>

      <div className="bot-edit-save">
        <button onClick={handleSave} className="bot-edit-save-button">
          Зберегти
        </button>
      </div>
    </div>
  );
}

export default BotEdit;
